import React, { useState } from "react";
import coin1 from "../assets/coin1.svg";
import coin2 from "../assets/coin2.svg";
import coin3 from "../assets/coin3.svg";
import coin4 from "../assets/coin4.svg";
import coin5 from "../assets/coin5.svg";
import coin6 from "../assets/coin6.svg";
import Mon1 from "../assets/Mon1.svg";
import Mon2 from "../assets/Mon2.svg";
import Mon3 from "../assets/Mon3.svg";
import Mon4 from "../assets/Mon4.svg";
import Mon5 from "../assets/Mon5.svg";
import Mon6 from "../assets/Mon6.svg";

import "../index.css";
import arrow from "../assets/redarrow.svg";
import { useEffect } from "react";
import axios from "axios";

const COINS = [
  {
    id: "1",
    icon: Mon1,
  },
  {
    id: "2",
    icon: Mon2,
  },
  {
    id: "3",
    icon: Mon3,
  },
  {
    id: "4",
    icon: Mon4,
  },
  {
    id: "5",
    icon: Mon5,
  },
  {
    id: "6",
    icon: Mon6,
  },
];

const Active = ({ className, title }) => {

    const [coins, setCoins] = useState([])

    useEffect(() => {
        axios.get("https://companytasks.ru/backend/get_coin_data.php").then(res => {
            setCoins(res.data);
        })
    }, [])

  return (
    <div className={className}>
      <h2 className="text-white text-[32px] text-center font-jura font-bold xl:text-[48px] ">
        {title}
      </h2>
      <div className="block bg:py-[35px]">
        <div className="hidden xl:flex xl:flex-col gap-8">
          <div className="flex gap-4 justify-center">
            {COINS.map((item) => (
              <img src={item.icon} alt="icon" key={item.id} />
            ))}
          </div>
          <div className="flex flex-col gap-4 ml-10">
            <div className="flex justify-between">
              <p className="text-white font-gilSemi w-[40px] text-[18px]"></p>
              <p className="text-white font-gilSemi w-[220px] text-[18px]"></p>
              <p className="text-white font-gilSemi w-[220px] text-[18px]">Цена</p>
              <p className="text-white font-gilSemi w-[220px] text-[18px]">Цена час назад</p>
              <p className="text-white font-gilSemi w-[220px] text-[18px]">Цена день назад</p>
              <p className="text-white font-gilSemi w-[220px] text-[18px]">
                Открытый интерес
              </p>
              <p className="text-white font-gilSemi w-[220px] text-[18px]">
                Объем торгов за 24 час
              </p>
            </div>
            <div className="flex flex-col gap-4">
              {coins?.map((item) => (
                <div key={item.id} className="flex items-center">
                  <div className="flex items-center">
                    <img src={item.img} alt="icon" className="h-[40px] w-[40px]" />
                    <div className="flex">
                      <p className="text-white w-[230px] ml-8 font-gil text-base">
                        {item.coin}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-start">
                    <span className="text-gray-400 font-gil text-[16px] w-[200px]">
                      {" "}
                      {item.price}
                    </span>
                    <span className="text-gray-400 font-gil ml-9 text-[16px] w-[220px]">
                      {" "}
                      {item.prevPrice1h}
                    </span>
                    <span className="text-gray-400 font-gil ml-5 text-[16px] w-[220px]">
                      {" "}
                      {item.prevPrice24h}
                    </span>
                    <span className="text-gray-400 font-gil ml-6 text-[16px] w-[220px]">
                      {" "}
                      {item.openinterest}
                    </span>
                    <span className="text-gray-400 font-gil ml-5 text-[16px] w-[220px]">
                      {" "}
                      {item.volume}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className=" mt-8 px-4 py-5 flex flex-col gap-4 md:hidden xl:hidden">
          {coins?.map((item) => (
            <div key={item.id} className="flex flex-col items-start">
            <div className="flex items-center">
              <img src={item.img} alt="icon" className="h-[40px] w-[40px]" />
              <div className="flex">
                <p className="text-white w-[230px] ml-2 font-gil text-base">
                  Актив: {item.coin}
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-2 mt-3 justify-start w-full">
              <span className="text-gray-400 font-gil text-[16px] w-full">
                {"Цена: "}
                {item.price}
              </span>
              <span className="text-gray-400 font-gil text-[16px] w-full">
                {"Цена час назад: "}
                {item.prevPrice1h}
              </span>
              <span className="text-gray-400 font-gil text-[16px] w-full">
                {"Цена день назад: "}
                {item.prevPrice24h}
              </span>
              <span className="text-gray-400 font-gil text-[16px] w-full">
                {"Открытый интерес:  "}
                {item.openinterest}
              </span>
              <span className="text-gray-400 font-gil text-[16px] w-full">
                {"Объем торгов за 24 час: "}
                {item.volume}
              </span>
            </div>
          </div>
          ))}
        </div>
        <div className=" mt-8 px-4 py-5  flex-col gap-4 hidden md:flex  xl:hidden">
          <div className="flex gap-3 justify-center">
            {COINS.map((item) => (
              <img
                src={item.icon}
                alt="icon"
                key={item.id}
                className="w-[155px] xl:w-[180px]"
              />
            ))}
          </div>
          <div className="flex flex-col gap-4 overflow-scroll h-[929px]">
            {coins?.map((item) => (
              <div
                key={item.id}
                className={`flex flex-col gap-2 ${
                  item.id === coins.length.toString()
                    ? "border-b-[0px] "
                    : "border-b-[1px] "
                } border-gray-500 py-3`}
              >
                <div className="flex items-center gap-4">
                  <img src={item.img} className="w-[30px]" alt={item.name} />
                  <div className="flex items-center gap-3">
                    <span className="text-white font-gil text-base">
                      Актив: {item.coin}
                    </span>
                    <span className="text-[#ACACAC] font-gil text-[12px]">
                    Цена: {item.price}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex items-center justify-between">
                    <span className="text-gray-400 font-gil text-[16px]">
                        Цена час назад: {item.prevPrice1h}
                    </span>
                  </div>
                  <div className="flex items-center  justify-between">
                    <span className="text-gray-400 font-gil text-[16px]">
                        Цена день назад: {item.prevPrice24h}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-gray-400 font-gil text-[16px]">
                        Открытый интерес: {item.openinterest}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-gray-400 font-gil text-[16px]">
                        Объем торгов за 24 час: {item.volume}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Active;
