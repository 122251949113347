import React from "react";

const Bonuses = ({ className, title }) => {
    return (
        <div className={className}>
            <h2 className="text-primary text-[32px] text-center font-jura font-semibold  md:text-[40px]">
                {title}
            </h2>
            <span className="text-[20px] font-gil text-white text-center md:text-[24px]">
                Доступен только для пользователей торговых советников.
            </span>
            <span className="text-gun font-gil text-[14px]">
                Стоимость 20$ ежемесячно
            </span>
        </div>
    );
};

export default Bonuses;
