import React from "react";

const Download = ({ className, title }) => {
    return (
        <div className={className}>
            <h2 className="text-primary text-[32px] text-center font-jura font-semibold  md:text-[40px]">
                {title}
            </h2>
            <a href="document.pdf" target="_blank" className="button flex items-center px-20 py-3 justify-center font-gil text-[18px] text-white">Скачать</a>
        </div>
    );
};

export default Download;