import React, {useRef,Component , useState, useEffect} from "react";
import "../index.css";
import { Link, useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useCookies } from "react-cookie";

import axios from 'axios';

const Login = () => {

  const navigate = useNavigate()

  const [message, setMessage] = useState('');
  const [message1, setMessage1] = useState('');
  const [cookies, setCookie] = useCookies(["user", "pas"]);

  const showError = (msg) => toast.error(msg, {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    hideProgressBar: true,
    theme: "dark",
  });;

  const handleChange = event => {
    setMessage(event.target.value);
    setCookie("user",event.target.value, { path: "/" });
  };
  
  const handleChange1 = event => {
    setMessage1(event.target.value);
    setCookie("pas",event.target.value, { path: "/" });
  };

  function register() {

    axios('https://companytasks.ru/backend/register.php', {
        method: 'POST',
        async: true,
        crossDomain: true,
        data: {'user':cookies['user'], 'password':cookies['pas']},
        headers: {
            "accept": "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8",
            "accept-language": "en-US,en;q=0.9,ru;q=0.8",
            "content-type": "application/x-www-form-urlencoded",
        }
        }).then(function (response) {
        var r = response['data'];
        if (r['status'] != "ok"){
            showError("Не правильный Email или пароль!")
        }
        else{
            navigate("/form")
        }
    })
    .catch((error) => {
        console.log(error)
    })
  }

  useEffect(() => {
    if (cookies["user"]) {
        navigate("/form")
    }
  }, [])

  return (
    <div className="second h-[100vh] flex items-center justify-center">

      <div className="blockInf rounded-2xl py-8 px-2 ml:px-10">
        <h2 className="text-primary text-3xl font-jura font-normal">Sign in</h2>
        <div className="flex flex-col gap-4 pt-7 ml:items-center">
          <div className="flex justify-between ml:gap-16 flex-col ml:flex-row">
            <span className="text-white text-2xl font-juru font-[400]">
              E-mail
            </span>
            <input
              type="email"
              name="email"
              id="email"
              value={message}
              onChange={handleChange}
              className=" bg-transparent outline-none pl-5 py-1.5 border-[1px] border-line text-white text-xl rounded-[10px] font-gil"
              placeholder="Email"
            />
          </div>
          <div className="flex justify-between ml:gap-8 flex-col ml:flex-row">
            <span className="text-white text-2xl font-juru font-[400]">
              Password
            </span>
            <input
              value={message1}
              onChange={handleChange1}
              type="password"
              name="password"
              id="password"
              className=" bg-transparent outline-none pl-5 py-1.5 border-[1px] border-line text-white text-xl rounded-[10px] font-gil"
              placeholder="Password"
            />
          </div>
          <button
            onClick={register}            
            className="text-white text-xl w-full font-gilSemi hover:bg-white hover:text-black transition-all flex items-center justify-center py-2 border-[1px] border-line rounded-xl mt-3"
          >
            Sign in
          </button>
        </div>
      </div>

      <ToastContainer />

    </div>
  );
};

export default Login;
