import { useEffect, useState } from "react";
import what from "../assets/what.svg";
import line from "../assets/line.svg";
import axios from "axios";
import { useCookies } from "react-cookie";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";

const Active2 = ({setActive1}) => {

    const [timeOpen, setTimeOpen] = useState(false)
    const [monetaOpen, setMonetaOpen] = useState(false);

    const [cookies, removeCookie] = useCookies(["user", "pas"]);

    const [bots, setBots] = useState([]);

    const [time, setTime] = useState([
        {
            id: "1",
            name: "1h",
            value: "1h"
        },
        {
            id: "2",
            name: "4h",
            value: "4h"
        },
        {
            id: "3",
            name: "1d",
            value: "1d"
        }
    ])

    const [selectedTime, setSelectedTime] = useState({});

    const [volumes_glav, setvolumes_glav] = useState([])
    const [volumes_hedge, setvolumes_hedge] = useState([])
    const [long_layers, setlong_layers] = useState([])
    const [short_layers, setshort_layers] = useState([])

    const [leverage, setLeverage] = useState()
    const [take, setTake] = useState()
    const [stop, setStop] = useState()
    const [pump, setPump] = useState()
    const [dump, setDump] = useState()

    useEffect(() => {
        axios("https://companytasks.ru/backend/register.php", {
            method: "POST",
            async: true,
            crossDomain: true,
            data: { user: cookies["user"], password: cookies["pas"] },
            headers: {
                accept: "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8",
                "accept-language": "en-US,en;q=0.9,ru;q=0.8",
                "content-type": "application/x-www-form-urlencoded",
            },
        })
            .then(function (response) {
                var r = response["data"];
                if (r["status"] != "ok") {
                    window.location.href = "/";
                } else {
                    const botsRef = JSON.parse(response.data.data.bot_array).bots;

                    botsRef.forEach((id) => {
                        axios
                            .get(
                                `https://companytasks.ru/backend/get_data.php?id=${id}&user=${cookies["user"]}&password=${cookies["pas"]}`,
                            )
                            .then((res) => {

                                setBots(prev => [...prev, res.data.data])

                                if (res.data.data.type == "1") {
                                    const data = JSON.parse(res.data.data.params);

                                    setSelectedTime({ name: data.time });

                                    setvolumes_glav(data.volumes_glav)
                                    setvolumes_hedge(data.volumes_hedge)

                                    setlong_layers(data.long_layers)
                                    setshort_layers(data.short_layers);

                                    setSelectedTime({name: data.time, value: data.time})

                                    setTake(data.take)
                                    setStop(data.stop)

                                    setDump(data.dump)
                                    setPump(data.pump)

                                    setLeverage(data.leverage)
                                }

                            });
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const showMessage = (msg, type) =>
        toast[type](msg, {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            hideProgressBar: true,
            theme: "dark",
        });

    const saveBot = (id) => {

        const params = JSON.stringify({
            "coin": "LTC",
            "time": selectedTime.name,
            "leverage": parseFloat(leverage),
            "dump": parseFloat(dump),
            "take": parseFloat(take),
            "stop": parseFloat(stop),
            "volumes_glav": volumes_glav.slice(0, 8).map(i => parseFloat(i)),
            "volumes_hedge": volumes_hedge.slice(0, 8).map(i => parseFloat(i)),
            "long_layers": long_layers.slice(0, 7).map(i => parseFloat(i)),
        })

        confirmAlert({
            title: "Сохранить изменения ?",
            message: "Вы уверены?",
            buttons: [
                {
                    label: "Да",
                    onClick: () => {
                        axios
                            .get(
                                `https://companytasks.ru/backend/set_data.php?user=${cookies["user"]}&password=${cookies["pas"]}&params=${params}&id=${id}`,
                            )
                            .then((res) => {
                                setTimeout(() => {
                                    setActive1(false)
                                }, 1000);
                                if (res.data.status == "ok") {
                                    showMessage(
                                        "Изменения сохранены!",
                                        "success",
                                    );
                                }
                            });
                    },
                },
                {
                    label: "Нет",
                },
            ],
        });
    };

    const clearBot = (id) => {
        confirmAlert({
            title: "Установить рекомендуемые настройки ?",
            message: "Вы уверены?",
            buttons: [
                {
                    label: "Да",
                    onClick: () => {
                        axios.get(`https://companytasks.ru/backend/recomend.php?user=${cookies["user"]}&password=${cookies["pas"]}&id=${id}`).then(() => {
                            window.location.reload()
                        })
                    },
                },
                {
                    label: "Нет",
                },
            ],
        });
    }

    return (
        <div className="flex overflow-auto max-h-[80vh] items-start justify-between flex-col md:flex-row w-full">

            <div>

                <div className="flex flex-wrap items-center justify-center md:justify-start mt-2 gap-1">
                    <span className="text-white md:text-start text-center w-full font-gilSemi text-[18px] md:w-[150px]">
                        Главная позиция
                    </span>
                    {
                        [0, 1, 2, 3, 4, 5, 6, 7].map(i => (
                            <input type="text" 
                                value={volumes_glav && volumes_glav[i]}
                                onChange={e => {
                                    if (volumes_glav != undefined) {
                                        setvolumes_glav(
                                            (
                                                prev,
                                            ) => [
                                                ...prev,
                                                (volumes_glav[
                                                    i
                                                ] =
                                                e.target.value),
                                            ],
                                        )
                                        volumes_glav.length = 8;
                                    } else {
                                        let arr = []
                                        arr[i] = e.target.value
                                        setvolumes_glav(arr)
                                    }
                                }}
                                key={i}
                                placeholder="Ввод"
                                className="hit w-[87px] h-[48px] text-white flex items-center pl-5 ml-2 font-gil text-[14px]  focus:outline-primary focus:outline"
                            />
                        ))
                    }
                </div>

                <div className="flex flex-wrap items-center justify-center md:justify-start mt-2 gap-1">
                    <span className="text-white md:text-start text-center w-full font-gilSemi text-[18px] md:w-[150px]">
                        Хэджирующая позиция
                    </span>
                    {
                        [0, 1, 2, 3, 4, 5, 6, 7].map(i => (
                            <input type="text" 
                                value={volumes_hedge && volumes_hedge[i]}
                                onChange={e => {
                                    if (volumes_hedge != undefined) {
                                        setvolumes_hedge(
                                            (
                                                prev,
                                            ) => [
                                                ...prev,
                                                (volumes_hedge[
                                                    i
                                                ] =
                                                e.target.value),
                                            ],
                                        )
                                        volumes_hedge.length = 8;
                                    } else {
                                        let arr = []
                                        arr[i] = e.target.value
                                        setvolumes_hedge(arr)
                                    }
                                }}
                                key={i}
                                placeholder="Ввод"
                                className="hit w-[87px] h-[48px] text-white flex items-center pl-5 ml-2 font-gil text-[14px]  focus:outline-primary focus:outline"
                            />
                        ))
                    }
                </div>

                <div className="flex flex-wrap items-center justify-center md:justify-start mt-2 gap-1">
                    <span className="text-white md:text-start text-center w-full font-gilSemi text-[18px] md:w-[150px]">
                        Long
                    </span>
                    {
                        [0, 1, 2, 3, 4, 5, 6].map(i => (
                            <input type="text" 
                                value={long_layers && long_layers[i]}
                                onChange={e => {
                                    if (long_layers != undefined) {
                                        setlong_layers(
                                            (
                                                prev,
                                            ) => [
                                                ...prev,
                                                (long_layers[
                                                    i
                                                ] =
                                                e.target.value),
                                            ],
                                        )
                                        long_layers.length = 7;
                                    } else {
                                        let arr = []
                                        arr[i] = e.target.value
                                        setlong_layers(arr)                                    
                                    }
                                }}
                                key={i}
                                placeholder="Ввод"
                                className="hit w-[87px] h-[48px] text-white flex items-center pl-5 ml-2 font-gil text-[14px]  focus:outline-primary focus:outline"
                            />
                        ))
                    }
                </div>

                <div className="flex relative flex-wrap items-center justify-center md:justify-start mt-2 gap-1">
                    <span className="text-white font-gilSemi text-[18px] w-[158px]">
                        Тайминг
                    </span>
                    <div
                        onClick={() => setTimeOpen(prev => !prev)}
                        className={` w-[180px] h-[48px] text-white  items-center pl-3 font-gil text-[14px] z-40 flex gap-5 ${
                            time === true
                                ? "bg-zinc-900 rounded-xl"
                                : "hit"
                        }`}
                    >
                        {selectedTime !== null
                            ? selectedTime.name
                            : "Выбрать время"}
                        <img
                            src={line}
                            alt=""
                            className={`${time === true ? " rotate-180" : ""}`}
                        />
                    </div>

                    {timeOpen && (
                        <div className="absolute left-[160px] z-40 top-[50px] bg-black pt-4 pb-2 w-[180px] rounded-xl">
                            {time.map((item) => (
                                <div    
                                    onClick={() => {
                                            setTimeOpen(false)
                                            setSelectedTime(
                                                item,
                                            )
                                        }
                                    }
                                    key={item.id}
                                    className="text-white font-gil px-2 hover:bg-primary transition-colors cursor-pointer rounded-[8px]"
                                >
                                    {item.name}
                                </div>
                            ))}
                        </div>
                    )}

                </div>

                
                <div className="flex flex-wrap items-center justify-center md:justify-start mt-2 gap-1">
                    <span className="text-white md:text-start text-center w-full font-gilSemi text-[18px] md:w-[150px]">
                        Leverage
                    </span>
                    <input type="text" 
                        value={leverage}
                        onChange={e => setLeverage(e.target.value)}
                        placeholder="Ввод"
                        className="hit w-[87px] h-[48px] text-white flex items-center pl-5 ml-2 font-gil text-[14px]  focus:outline-primary focus:outline"
                    />
                    <p className="text-white font-gilSemi text-[18px] ml-2 w-[150px]">%</p>
                </div>

                <div className="flex flex-wrap items-center justify-center md:justify-start mt-2 gap-1">
                    <span className="text-white md:text-start text-center w-full font-gilSemi text-[18px] md:w-[150px]">
                        Take profit
                    </span>
                    <input type="text" 
                        value={take}
                        onChange={e => setTake(e.target.value)}
                        placeholder="Ввод"
                        className="hit w-[87px] h-[48px] text-white flex items-center pl-5 ml-2 font-gil text-[14px]  focus:outline-primary focus:outline"
                    />
                    <p className="text-white font-gilSemi text-[18px] ml-2 w-[150px]">%</p>
                </div>

                <div className="flex flex-wrap items-center justify-center md:justify-start mt-2 gap-1">
                    <span className="text-white md:text-start text-center w-full font-gilSemi text-[18px] md:w-[150px]">
                        Stop loss
                    </span>
                    <input type="text" 
                        value={stop}
                        onChange={e => setStop(e.target.value)}
                        placeholder="Ввод"
                        className="hit w-[87px] h-[48px] text-white flex items-center pl-5 ml-2 font-gil text-[14px]  focus:outline-primary focus:outline"
                    />
                    <p className="text-white font-gilSemi text-[18px] ml-2 w-[150px]">%</p>
                </div>

                <div className="flex flex-wrap items-center justify-center md:justify-start mt-2 gap-1">
                    <span className="text-white md:text-start text-center w-full font-gilSemi text-[18px] md:w-[150px]">
                        DumP
                    </span>
                    <input type="text" 
                        value={dump}
                        onChange={e => setDump(e.target.value)}
                        placeholder="Ввод"
                        className="hit w-[87px] h-[48px] text-white flex items-center pl-5 ml-2 font-gil text-[14px]  focus:outline-primary focus:outline"
                    />
                    <p className="text-white font-gilSemi text-[18px] ml-2 w-[20px]">%</p>
                </div>

            </div>

            <div className="flex flex-col flex-1 h-full justify-between items-end">
                <div className="flex flex-col mt-10 h-full justify-between items-end">
                    <button
                        onClick={() => clearBot(bots.filter(i => i.type == "1")[0].id)}
                        className="h-[36px] text-center right-2 text-xs w-[250px] absolute top-2 text-white button font-gilSemi"
                    >
                        Установить рекомендуемые настройки
                    </button>
                    <div></div>
                    <button
                        onClick={() => saveBot(bots.filter(i => i.type == "1")[0].id)}
                        className="button w-[157px] h-[56px] flex items-center justify-center text-white font-gilSemi text-[18px]"
                    >
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Active2