import React from "react";
import logo from "../assets/logo.svg";
import mainLogo from "../assets/logo.png"

const Header = ({ className }) => {
  return (
    <div className={className}>
      <img src={logo} alt="logo" />
      <img className="w-20 h-14 object-cover" src={mainLogo} alt="logo" />
    </div>
  );
};

export default Header;
